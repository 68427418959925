.hover
  opacity: 0.5

  &:hover
    opacity: 1

.hover-parent:hover > .hover, .hover-active .hover
  opacity: 1

.contact-img
  margin-bottom: -48px

.bg-wave
  background-size: cover
  -webkit-background-size: cover
  background-repeat: no-repeat
  background-position: 50%

table
  border-collapse: separate !important
  border-spacing: 0 !important

.blur
  -webkit-filter: blur(3px)
  filter: blur(3px)
  -webkit-touch-callout: none

  /* iOS Safari
  -webkit-user-select: none

  /* Safari
  -khtml-user-select: none

  /* Konqueror HTML
  -moz-user-select: none

  /* Old versions of Firefox
  -ms-user-select: none

  /* Internet Explorer/Edge
  user-select: none

  /* Non-prefixed version, currently
   * supported by Chrome, Edge, Opera and Firefox

/* top-left border-radius

table tr
  &:first-child th
    &:first-child
      border-top-left-radius: 0.5rem !important

    &:last-child
      border-top-right-radius: 0.5rem !important

  &:last-child td
    &:first-child
      border-bottom-left-radius: 0.5rem !important

    &:last-child
      border-bottom-right-radius: 0.5rem !important

/* top-right border-radius

/* bottom-left border-radius

/* bottom-right border-radius

.text-python
  color: #3FB422

.text-python-hl
  color: #acc267

.text-hl
  color: #f92672

.text-hl-yellow
  color: #FBBF24

.caption
  color: #869ab8 !important
  font-size: 12px

.highlight-block
  background: #1e1e1e
  padding: 20px
  border-radius: 4px
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.25)

  pre, table
    background: #1e1e1e
    color: white

pre
  overflow: auto
  -ms-word-wrap: normal
  word-wrap: normal
  overflow-wrap: normal
  white-space: pre

\::-webkit-scrollbar
  width: 8px
  height: 8px

/* Track

\::-webkit-scrollbar-track
  background: transparent

/* Handle

\::-webkit-scrollbar-thumb
  background: #888

  &:hover
    background: #555

/* Handle on hover

// Default Background
.bg-default
  background-image: url('~/media/imgs/illustrations/linkdb-left.png'), url('~/media/imgs/illustrations/linkdb-right.png')

// United States Background
.bg-usa
  background-image: url('~/media/imgs/illustrations/linkdb-left-usa.png'), url('~/media/imgs/illustrations/linkdb-right-usa.png')

// France Background
.bg-france
  background-image: url('~/media/imgs/illustrations/linkdb-left-france.png'), url('~/media/imgs/illustrations/linkdb-right-france.png')

// India Background
.bg-india
  background-image: url('~/media/imgs/illustrations/linkdb-left-india.png'), url('~/media/imgs/illustrations/linkdb-right-india.png')

// Spain Background
.bg-spain
  background-image: url('~/media/imgs/illustrations/linkdb-left-spain.png'), url('~/media/imgs/illustrations/linkdb-right-spain.png')

// Sweden Background
.bg-sweden
  background-image: url('~/media/imgs/illustrations/linkdb-left-sweden.png'), url('~/media/imgs/illustrations/linkdb-right-sweden.png')
